import React, { useContext, useEffect } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { VortexDataContext } from 'providers/VortexDataProvider';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { ThemeVariant } from 'config';
import { namedTooltipList } from 'helpers/echart-utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

type YAxisData = {
  name: string;
  data: number[];
};

const getDefaultOptions = (
  theme: ThemeVariant,
  xAxisData: number[],
  yAxisDataList: YAxisData[],
  getThemeColor: (name: string) => string
) => ({
  color: [getThemeColor('primary'), getThemeColor('info')],
  tooltip: {
    trigger: 'axis',
    padding: 10,
    backgroundColor: getThemeColor('body-highlight-bg'),
    borderColor: getThemeColor('border-color'),
    textStyle: { color: getThemeColor('light-text-emphasis') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: (params: any) =>
      namedTooltipList(params, (value: any) => `◎${value.toFixed(4)}`)
  },
  xAxis: [
    {
      type: 'category',
      data: xAxisData,
      axisLabel: {
        interval: 1,
        show: false,
        color: getThemeColor('secondary-color'),
        align: 'left',
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        fontSize: 12.8
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: getThemeColor('secondary-bg')
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true,
        interval: 0,
        lineStyle: {
          color:
            theme === 'dark'
              ? getThemeColor('body-highlight-bg')
              : getThemeColor('secondary-bg')
        }
      },
      boundaryGap: false
    },
    {
      type: 'category',
      position: 'bottom',
      data: xAxisData,
      axisLabel: {
        formatter: (value: number) => `Epoch ${value}`,
        rotate: 45,
        showMaxLabel: true,
        showMinLabel: false,
        color: getThemeColor('secondary-color'),
        align: 'right',
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        fontSize: 12.8
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      boundaryGap: false
    }
  ],
  yAxis: {
    position: 'right',
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      show: false
    },
    axisLine: { show: false },
    axisLabel: { show: false }
  },
  series: yAxisDataList.map(({ name, data }) => ({
    name,
    type: 'line',
    data,
    showSymbol: false,
    symbol: 'circle'
  })),
  grid: {
    right: 2,
    left: 5,
    bottom: '60px',
    top: '2%',
    containLabel: false
  },
  animation: false
});

const BlockIncomeChart = ({ validatorId }: { validatorId: string }) => {
  const {
    config: { theme },
    getThemeColor
  } = useAppContext();
  const context = useContext(VortexDataContext);

  useEffect(() => {
    if (
      context?.epochIncomeState &&
      !context.epochIncomeState.isLoading &&
      !context.epochIncomeState.data.size
    ) {
      console.log('chart fetch');
      context.epochIncomeState.fetchData(validatorId, 611, 692);
    }
  }, [context]);

  if (!context) return <div>Loading...</div>;

  let { epochIncomeState } = context;

  if (epochIncomeState.isLoading) return <div>Loading...</div>;
  if (epochIncomeState.error) return <div>Error: {epochIncomeState.error}</div>;

  let epochIncomeData = epochIncomeState.data.get(validatorId) || [];
  let xAxisData = epochIncomeData.map(epochIncome => {
    return epochIncome.epoch;
  });

  let yAxisData = [
    {
      name: 'MEV Tips',
      data: epochIncomeData.map(
        epochIncome => epochIncome.medianIncome.mevTips / 1_000_000_000
      )
    },
    {
      name: 'Priority Fees',
      data: epochIncomeData.map(
        epochIncome => epochIncome.medianIncome.priorityFees / 1_000_000_000
      )
    }
  ];

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(theme, xAxisData, yAxisData, getThemeColor)}
      style={{ height: '316px', width: '100%' }}
    />
  );
};

export default BlockIncomeChart;
