import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { EpochIncome } from 'data/income';
import { formatLamports } from 'helpers/utils';

export const createEpochIncomeTableColumns = (
  clusterData: EpochIncome[]
): ColumnDef<EpochIncome>[] => {
  return [
    {
      accessorKey: 'epoch',
      header: 'Epoch',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        let clusterStake = clusterData.find(value => value.epoch === row.epoch)
          ?.stake;
        if (clusterStake === undefined) {
          return 'NA';
        }
        let stakePct = row.stake / clusterStake;
        let expectedBlocks = stakePct * 432_000;
        return `${expectedBlocks.toFixed(2)} (${(100 * stakePct).toFixed(
          4
        )}% stake)`;
      },
      header: 'Expected Blocks',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        let slots = row.skippedSlots + row.confirmedSlots;
        let pct = slots > 0 ? (100 * row.skippedSlots) / slots : 0;
        return `${pct.toFixed(2)}% (${row.skippedSlots}/${slots})`;
      },
      header: 'Skip Rate',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => formatLamports(row.medianIncome.priorityFees),
      header: 'Median Priority Fee',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => formatLamports(row.totalIncome.priorityFees),
      header: 'Total Priority Fees',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => formatLamports(row.medianIncome.mevTips),
      header: 'Median MEV Tips',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => formatLamports(row.totalIncome.mevTips),
      header: 'Total MEV Tips',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];
};

const EpochIncomeTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochIncomeTable;
