import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { formatLamports } from 'helpers/utils';
import { EpochIncomeLeaderboardRecord } from 'providers/VortexDataProvider';

export const createIncomeLeaderboardTableColumns =
  (): ColumnDef<EpochIncomeLeaderboardRecord>[] => {
    return [
      {
        accessorFn: (_row, index) => {
          return index + 1;
        },
        header: 'Income Ranking',
        meta: {
          cellProps: { className: 'text-body' }
        }
      },
      {
        accessorKey: 'nodeName',
        header: 'Validator',
        meta: {
          cellProps: { className: 'text-body' }
        }
      },
      {
        accessorKey: 'nodeAddress',
        header: 'Validator Address',
        cell: ({ cell, row }) => {
          return (
            <a
              href={`/income/${row.original.nodeAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.original.nodeAddress}
            </a>
          );
        },
        meta: {
          cellProps: { className: 'text-body' }
        }
      },
      {
        accessorKey: 'confirmedSlots',
        header: 'Produced Blocks',
        meta: {
          cellProps: { className: 'text-body' }
        }
      },
      {
        accessorFn: row => {
          return formatLamports(
            row.medianIncome.baseFees +
              row.medianIncome.priorityFees +
              row.medianIncome.mevTips
          );
        },
        header: 'Median Block Income',
        meta: {
          cellProps: { className: 'text-body' }
        }
      },
      {
        accessorFn: row => {
          return formatLamports(row.medianIncome.mevTips);
        },
        header: 'Median MEV Tips',
        meta: {
          cellProps: { className: 'text-body' }
        }
      },
      {
        accessorFn: row => {
          return formatLamports(row.medianIncome.priorityFees);
        },
        header: 'Median Priority Fees',
        meta: {
          cellProps: { className: 'text-body' }
        }
      }
    ];
  };

const EpochLeaderboardTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochLeaderboardTable;
